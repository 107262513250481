import React, { Component } from 'react'
import $ from "jquery";

//animation
import Fade from 'react-reveal';

import 'react-multi-carousel/lib/styles.css';




/* images */
//import sec-img1 from ''
//import sec-img2 from ''
//import Bg-hero from ''
//import sec-img3 ''
//import left from ''
//import right from ''
//import sec-img4 from ''


/*imagens produtos */
import tomato from './../assets/produtos/tomato.png'
import masala from './../assets/produtos/masala.png'
import beef from './../assets/produtos/beef.png'
import curry from './../assets/produtos/curry.png'
import shrimp from './../assets/produtos/shrimp.png'
import mushrooms from './../assets/produtos/mushrooms.png'
import chicken from './../assets/produtos/chicken.png'
import veggies from './../assets/produtos/veggies.png'
import ezy from './../assets/produtos/ezy.png'
import migoreng from './../assets/produtos/migoreng.png'

import banner from './../assets/banner_site.png'
import ad from './../assets/ad.png'



class Home extends Component {
    constructor(){
        super()
        this.state={
            produtos:[
                {name:"Noodles Mi Goreng", img:migoreng, link:"https://panegara.pt/shop/product/massa-inst-mi-goreng-koka-pac-30-x-85-gr-278?category=12", alt:"Embalagem da koka noodles, noodles com sabor a Mi Goreng, à venda na panegara"},
                {name:"Noodles Tomate", img:tomato, link:"https://panegara.pt/shop/product/massa-instant-tomate-pack-koka-30-x-85g-200?category=12", alt:"Embalagem da koka noodles, noodles com sabor a Tomate, à venda na panegara"},
                {name:"Noodles Masala", img:masala, link:"https://panegara.pt/shop/product/massa-instant-masala-pack-koka-30-x-85g-202?category=12", alt:"Embalagem da koka noodles, noodles com sabor a Masala, à venda na panegara"},
                {name:"Noodles Beef", img:beef, link:"https://panegara.pt/shop/product/massa-instant-carne-pack-koka-30-x-85g-201?category=12", alt:"Embalagem da koka noodles, noodles com sabor a Beef, à venda na panegara"},
                {name:"Noodles Caril", img:curry, link:"https://panegara.pt/shop/product/massa-instant-caril-pack-koka-30-x-85g-196?category=12", alt:"Embalagem da koka noodles, noodles com sabor a Caril, à venda na panegara"},
                {name:"Noodles Camarão", img:shrimp, link:"https://panegara.pt/shop/product/massa-instant-camarao-pack-koka-30-x-85g-195?category=12", alt:"Embalagem da koka noodles, noodles com sabor a Camarão, à venda na panegara"},
                {name:"Noodles Cogumelos", img:mushrooms, link:"https://panegara.pt/shop/product/massa-instant-cogumelos-pack-koka-30-x-85g-197?category=12", alt:"Embalagem da koka noodles, noodles com sabor a Cogumelos, à venda na panegara"},
                {name:"Noodles Galinha", img:chicken, link:"https://panegara.pt/shop/product/massa-instant-galinha-pack-koka-30-x-85g-198?category=12", alt:"Embalagem da koka noodles, noodles com sabor a Galinha, à venda na panegara"},
                {name:"Noodles Vegetais", img:veggies, link:"https://panegara.pt/shop/product/massa-instant-legumes-pack-koka-30-x-85g-199?category=12", alt:"Embalagem da koka noodles, noodles com sabor a Vegetais, à venda na panegara"},
                {name:"Ezy-cook Noodles", img:ezy, link:"https://panegara.pt/shop/product/massa-instant-ezy-cook-koka-6-x-375g-206?category=12", alt:"Embalagem da koka noodles, noodles para chefes de cozinha e refeições sem sabor, à venda na panegara"},

            ],
        }
        this.handleForm = this.handleForm.bind(this)
        this.openForm = this.openForm.bind(this)
        this.handleAddition = this.handleAddition.bind(this)
    }

    componentDidMount() {
        document.querySelectorAll('a[href^="#"]').forEach(anchor => {
            anchor.addEventListener('click', function (e) {
                e.preventDefault();

                document.querySelector(this.getAttribute('href')).scrollIntoView({
                    behavior: 'smooth'
                });
            });
        });
    }

    openForm(){
        this.setState({
            formactivation:!this.state.formactivation
        })
    }
    handleForm(e){
        e.preventDefault();
        this.setState({
        contactEmail: '',
        contactMessage: '',
        formactivation:!this.state.formactivation
        });
    
        $.ajax({
        url: process.env.NODE_ENV !== "production" ? '/getMail' : "newsletter.php",
        type: 'POST',
        data: {
            'email': this.state.email,
        },
        cache: false,
        success: function(data) {
            // Success..
            this.setState({
            contactEmail: 'success',
            contactMessage: '<div class="sucess_contact"><h2>Foi adicionado à nossa lista!</h2></div>'
            });
            $('#containerText').slideUp();
            $('#containerText').after(this.state.contactMessage);
            console.log('success', data);
        }.bind(this),
        // Fail..
        error: function(xhr, status, err) {
            console.log(xhr, status);
            console.log(err);
            this.setState({
            contactEmail: 'danger',
            contactMessage: '<h1>Pedimos desculpa mas houve um erro no algoritmo</h1><p>Tente outra vez mais tarde, ou contacte directamente pelo info@themeeco.com</p>'
            });
            console.log(this.state.email + this.state.message + 'fail');
        }.bind(this)
        })
    }

    handleAddition(e){
        this.setState({
          [e.target.name]: e.target.value,
        });
      }

  render() {
//  style for first sec // style={{background: `url(${bgImg}) no-repeat center center`, backgroundSize:`cover`}}
    return (
        <main className="home_theme">
            <header id="home" className="hero masthead" style={{background: `url(${banner}) no-repeat center right`,backgroundSize:`cover`}}>
                <div className="cta__ hero__container">
                    <Fade forever right>
                        <div className="cta__containertext ninja">
                            <div className="container__text--white">
                                <h2 style={{'textShadow':'2px 1px 4px #000'}}>
                                    <span style={{'fontFamily':'TheHistoria','fontSize':'2em', 'lineHeight':'1.4em'}}>Rápido como<br/> 
                                        <span style={{'fontFamily':'TheHistoria', 'paddingLeft':'2em'}}>um </span>
                                        <span style={{'fontSize': '2em','fontFamily':'TheHistoria'}}>Ninja</span>
                                    </span>
                                </h2>
                            </div>
                        </div>
                    </Fade>
                </div> 
            </header>
            <section id="inicio" className="hero firstsec">
                <Fade forever left>
                    <div className="left-brush">
                        <img src={mushrooms} alt="Noodles da Koka de cogumelos, embalagem da koka"/> 
                    </div>
                </Fade>
                <div className="principal">
                    <Fade forever >
                        <div className="cta__containertext">
                            <div className="container__text--white">
                                <h2>A KOKA NOODLES</h2>
                                <p style={{'fontSize':'2em','color':'#888'}}>
                                Com a Koka noodles podes ter deliciosas, rápidas e económicas refeições à tua mesa. <br/>1,2,3 e já está!
                                </p>
                            </div>
                        </div>
                    </Fade>
                </div>
                 <Fade forever right>
                    <div className="right-brush">
                        <img src={curry} alt="Noodles da Koka de Caril, embalagem da koka"/>
                    </div>
                </Fade>
            </section>
            <section id="produtos" className="itens-section">
                <Fade forever delay={100}>
                    <div className="title">
                        <h2>Os nossos produtos</h2>
                    </div>
                </Fade>
                <div className="item-list">
                    <Fade forever delay={300} cascade>
                        {this.state.produtos.map((item, index)=>{
                                return(
                                    <div  key={index + "item"} className="items_container">
                                        <div className="items_content">
                                            <div className="item">
                                                <a target="_blank" rel="noopener noreferrer" href={item.link} >
                                                    <img src={item.img} alt={item.alt}/>
                                                    <div className="item_link">{item.name}</div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                    </Fade>
                </div>
            </section>
            <section id="passatempo" className="hero redbackground">
                {/* <div className="blockup"></div> */}
                {/* <div className="cta__">
                <Fade forever delay={300}><div className="cta__image"> <img className="diagonal--inverted" src={ad} alt="Passatempo da koka de natal para ganhar um Funko pop "/></div></Fade>
                    <Fade forever delay={1000}>
                        <div className="cta__containertext">
                            <div className="container__text--white">
                                <h2>Participa no Challenge Cooking Koka Noodles</h2>
                                    <p style={{'fontSize':'2em','lineHeight':'1.2em'}}>
                                    Prova que és um verdadeiro ninja a cozinhar e cria a tua própria receita com a KOKA NOODLES! 🐱‍👤
                                    <br/><br/>
                                    🎥 Grava um vídeo até 1 minuto com uma receita criada por ti utilizando os noodles da KOKA.<br/>
                                    Partilha no teu Instagram mencionando @koka.portugal e utilizando #challengecookingkokanoodles<br/><br/>
                                    📆 Tens até dia 12 de maio para participar. Os 3 finalistas vão poder gravar a sua receita profissionalmente nos estúdios da KOKA NOODLES. 
                                    <br/> <br/>
                                    🏆 O Vencedor irá receber um voucher de 150€ para utilizar na Worten, 30 embalagens de KOKA NOODLES, um saco de pano e 1 conjunto de pauzinhos personalizados.
                                    </p>
                                <div className="buttons-row">
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/koka.portugal/"><button className="button__theme">Instagram</button></a>
                                    <a target="_blank" rel="noopener noreferrer" href="https://www.koka.pt/regAtiv.pdf"><button className="button__theme">Regulamento</button></a>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div> */}
                {/* <div className="blockdown"></div> */}

            </section>
            <section className="hero subscription">

            </section>
      </main>
    )
  }
}

export default Home

